export const defaultConfig = {
  annotations: {
    position: "front",
    yaxis: [],
    xaxis: [],
    points: [],
    texts: [],
    images: [],
    shapes: [],
  },
  chart: {
    animations: {
      enabled: true,
      easing: "easeinout",
      speed: 800,
      animateGradually: {
        delay: 150,
        enabled: true,
      },
      dynamicAnimation: {
        enabled: true,
        speed: 350,
      },
    },
    background: "transparent",
    locales: [
      {
        name: "en",
        options: {
          months: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],
          shortMonths: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          days: [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ],
          shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          toolbar: {
            exportToSVG: "Download SVG",
            exportToPNG: "Download PNG",
            exportToCSV: "Download CSV",
            menu: "Menu",
            selection: "Selection",
            selectionZoom: "Selection Zoom",
            zoomIn: "Zoom In",
            zoomOut: "Zoom Out",
            pan: "Panning",
            reset: "Reset Zoom",
          },
        },
      },
    ],
    defaultLocale: "en",
    dropShadow: {
      enabled: false,
      top: 2,
      left: 2,
      blur: 4,
      color: "#000",
      opacity: 0.35,
    },
    events: {},
    foreColor: "#373d3f",
    fontFamily: "Helvetica, Arial, sans-serif",
    height: "auto",
    parentHeightOffset: 15,
    redrawOnParentResize: true,
    redrawOnWindowResize: true,
    offsetX: 0,
    offsetY: 0,
    selection: {
      enabled: false,
      type: "x",
      fill: {
        color: "#24292e",
        opacity: 0.1,
      },
      stroke: {
        width: 1,
        color: "#24292e",
        opacity: 0.4,
        dashArray: 3,
      },
      xaxis: {},
      yaxis: {},
    },
    sparkline: {
      enabled: false,
    },
    brush: {
      enabled: false,
      autoScaleYaxis: true,
    },
    stacked: false,
    stackType: "normal",
    toolbar: {
      show: true,
      offsetX: 0,
      offsetY: 0,
      tools: {
        download: true,
        selection: false,
        zoom: false,
        zoomin: false,
        zoomout: false,
        pan: false,
        reset: false,
        customIcons: [],
      },
      export: {
        csv: {
          columnDelimiter: ",",
          headerCategory: "category",
          headerValue: "value",
        },
        png: {},
        svg: {},
      },
      autoSelected: "zoom",
    },
    type: "line",
    width: "100%",
    zoom: {
      enabled: true,
      type: "x",
      autoScaleYaxis: false,
      zoomedArea: {
        fill: {
          color: "#90CAF9",
          opacity: 0.4,
        },
        stroke: {
          color: "#0D47A1",
          opacity: 0.4,
          width: 1,
        },
      },
    },
  },
  plotOptions: {
    area: {
      fillTo: "origin",
    },
    bar: {
      horizontal: false,
      columnWidth: "70%",
      barHeight: "70%",
      distributed: false,
      borderRadius: 0,
      rangeBarOverlap: true,
      rangeBarGroupRows: false,
      colors: {
        ranges: [],
        backgroundBarColors: [],
        backgroundBarOpacity: 1,
        backgroundBarRadius: 0,
      },
      dataLabels: {
        position: "top",
        maxItems: 100,
        hideOverflowingLabels: true,
        orientation: "horizontal",
      },
    },
    bubble: {},
    candlestick: {
      colors: {
        upward: "#00B746",
        downward: "#EF403C",
      },
      wick: {
        useFillColor: true,
      },
    },
    boxPlot: {
      colors: {
        upper: "#00E396",
        lower: "#008FFB",
      },
    },
    heatmap: {
      radius: 2,
      enableShades: true,
      shadeIntensity: 0.5,
      reverseNegativeShade: false,
      distributed: false,
      useFillColorAsStroke: false,
      colorScale: {
        inverse: false,
        ranges: [],
      },
    },
    treemap: {
      enableShades: true,
      shadeIntensity: 0.5,
      distributed: false,
      reverseNegativeShade: false,
      useFillColorAsStroke: false,
      colorScale: {
        inverse: false,
        ranges: [],
      },
    },
    radialBar: {
      inverseOrder: false,
      startAngle: 0,
      endAngle: 360,
      offsetX: 0,
      offsetY: 0,
      hollow: {
        margin: 5,
        size: "50%",
        background: "transparent",
        imageWidth: 150,
        imageHeight: 150,
        imageOffsetX: 0,
        imageOffsetY: 0,
        imageClipped: true,
        position: "front",
        dropShadow: {
          enabled: false,
          top: 0,
          left: 0,
          blur: 3,
          color: "#000",
          opacity: 0.5,
        },
      },
      track: {
        show: true,
        background: "#f2f2f2",
        strokeWidth: "97%",
        opacity: 1,
        margin: 5,
        dropShadow: {
          enabled: false,
          top: 0,
          left: 0,
          blur: 3,
          color: "#000",
          opacity: 0.5,
        },
      },
      dataLabels: {
        show: true,
        name: {
          show: true,
          fontSize: "16px",
          fontWeight: 600,
          offsetY: 0,
        },
        value: {
          show: true,
          fontSize: "14px",
          fontWeight: 400,
          offsetY: 16,
        },
        total: {
          show: false,
          label: "Total",
          fontSize: "16px",
          fontWeight: 600,
        },
      },
    },
    pie: {
      customScale: 1,
      offsetX: 0,
      offsetY: 0,
      startAngle: 0,
      endAngle: 360,
      expandOnClick: true,
      dataLabels: {
        offset: 0,
        minAngleToShowLabel: 10,
      },
      donut: {
        size: "65%",
        background: "transparent",
        labels: {
          show: false,
          name: {
            show: true,
            fontSize: "16px",
            fontWeight: 600,
            offsetY: -10,
          },
          value: {
            show: true,
            fontSize: "20px",
            fontWeight: 400,
            offsetY: 10,
          },
          total: {
            show: false,
            showAlways: false,
            label: "Total",
            fontSize: "16px",
            fontWeight: 400,
          },
        },
      },
    },
    polarArea: {
      rings: {
        strokeWidth: 1,
        strokeColor: "#e8e8e8",
      },
      spokes: {
        strokeWidth: 1,
        connectorColors: "#e8e8e8",
      },
    },
    radar: {
      offsetX: 0,
      offsetY: 0,
      polygons: {
        strokeWidth: 1,
        strokeColors: "#e8e8e8",
        connectorColors: "#e8e8e8",
        fill: {},
      },
    },
  },
  dataLabels: {
    enabled: true,
    textAnchor: "middle",
    distributed: false,
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: "12px",
      fontWeight: 600,
    },
    background: {
      enabled: true,
      foreColor: "#fff",
      borderRadius: 2,
      padding: 4,
      opacity: 0.9,
      borderWidth: 1,
      borderColor: "#fff",
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: "#000",
        opacity: 0.45,
      },
    },
    dropShadow: {
      enabled: false,
      top: 1,
      left: 1,
      blur: 1,
      color: "#000",
      opacity: 0.45,
    },
  },
  fill: {
    type: "solid",
    opacity: 0.85,
    gradient: {
      shade: "dark",
      type: "horizontal",
      shadeIntensity: 0.5,
      inverseColors: true,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 50, 100],
      colorStops: [],
    },
    image: {
      src: [],
    },
    pattern: {
      style: "squares",
      width: 6,
      height: 6,
      strokeWidth: 2,
    },
  },
  forecastDataPoints: {
    count: 0,
    fillOpacity: 0.5,
    dashArray: 4,
  },
  grid: {
    show: true,
    borderColor: "#e0e0e0",
    strokeDashArray: 0,
    position: "back",
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: true,
      },
    },
    row: {
      opacity: 0.5,
    },
    column: {
      opacity: 0.5,
    },
    padding: {
      top: 0,
      right: 10,
      bottom: 0,
      left: 12,
    },
  },
  labels: [],
  legend: {
    show: true,
    showForSingleSeries: false,
    showForNullSeries: true,
    showForZeroSeries: true,
    floating: false,
    position: "bottom",
    horizontalAlign: "center",
    inverseOrder: false,
    fontSize: "12px",
    fontWeight: 400,
    offsetX: -20,
    offsetY: 4,
    customLegendItems: [],
    labels: {
      useSeriesColors: false,
    },
    markers: {
      width: 12,
      height: 12,
      strokeWidth: 0,
      strokeColor: "#fff",
      radius: 12,
      offsetX: 0,
      offsetY: 0,
    },
    itemMargin: {
      horizontal: 5,
      vertical: 2,
    },
    onItemClick: {
      toggleDataSeries: true,
    },
    onItemHover: {
      highlightDataSeries: true,
    },
  },
  markers: {
    discrete: [],
    size: 0,
    strokeColors: "#fff",
    strokeWidth: 2,
    strokeOpacity: 0.9,
    strokeDashArray: 0,
    fillOpacity: 1,
    shape: "circle",
    width: 8,
    height: 8,
    radius: 2,
    offsetX: 0,
    offsetY: 0,
    showNullDataPoints: true,
    hover: {
      sizeOffset: 3,
    },
  },
  noData: {
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
    style: {
      fontSize: "14px",
    },
  },
  responsive: [],
  states: {
    normal: {
      filter: {
        type: "none",
        value: 0,
      },
    },
    hover: {
      filter: {
        type: "lighten",
        value: 0.1,
      },
    },
    active: {
      allowMultipleDataPointsSelection: false,
      filter: {
        type: "darken",
        value: 0.5,
      },
    },
  },
  title: {
    align: "left",
    margin: 5,
    offsetX: 0,
    offsetY: 0,
    floating: false,
    style: {
      fontSize: "14px",
      fontWeight: 900,
    },
  },
  subtitle: {
    align: "left",
    margin: 5,
    offsetX: 0,
    offsetY: 30,
    floating: false,
    style: {
      fontSize: "12px",
      fontWeight: 400,
    },
  },
  stroke: {
    show: true,
    curve: "smooth",
    lineCap: "butt",
    width: 2,
    dashArray: 0,
  },
  tooltip: {
    enabled: true,
    shared: true,
    followCursor: false,
    intersect: false,
    inverseOrder: false,
    fillSeriesColor: false,
    theme: "light",
    cssClass: "",
    style: {
      fontSize: "12px",
    },
    onDatasetHover: {
      highlightDataSeries: false,
    },
    x: {
      show: true,
      format: "dd MMM",
    },
    y: {
      title: {},
    },
    z: {
      title: "Size: ",
    },
    marker: {
      show: true,
    },
    items: {
      display: "flex",
    },
    fixed: {
      enabled: false,
      position: "topRight",
      offsetX: 0,
      offsetY: 0,
    },
  },
  xaxis: {
    type: "category",
    categories: [],
    convertedCatToNumeric: false,
    offsetX: 0,
    offsetY: 0,
    labels: {
      show: true,
      rotate: -45,
      rotateAlways: false,
      hideOverlappingLabels: true,
      trim: false,
      maxHeight: 120,
      showDuplicates: true,
      style: {
        colors: [],
        fontSize: "12px",
        fontWeight: 400,
        cssClass: "",
      },
      offsetX: 0,
      offsetY: 0,
      datetimeUTC: true,
      datetimeFormatter: {
        year: "yyyy",
        month: "MMM 'yy",
        day: "dd MMM",
        hour: "HH:mm",
        minute: "HH:mm:ss",
        second: "HH:mm:ss",
      },
    },
    axisBorder: {
      show: true,
      color: "#e0e0e0",
      width: "100%",
      height: 1,
      offsetX: 0,
      offsetY: 0,
    },
    axisTicks: {
      show: true,
      color: "#e0e0e0",
      height: 6,
      offsetX: 0,
      offsetY: 0,
    },
    tickPlacement: "on",
    floating: false,
    position: "bottom",
    title: {
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "12px",
        fontWeight: 900,
        cssClass: "",
      },
    },
    crosshairs: {
      show: true,
      width: 1,
      position: "back",
      opacity: 0.9,
      stroke: {
        color: "#b6b6b6",
        width: 1,
        dashArray: 3,
      },
      fill: {
        type: "solid",
        color: "#B1B9C4",
        gradient: {
          colorFrom: "#D8E3F0",
          colorTo: "#BED1E6",
          stops: [0, 100],
          opacityFrom: 0.4,
          opacityTo: 0.5,
        },
      },
      dropShadow: {
        enabled: false,
        left: 0,
        top: 0,
        blur: 1,
        opacity: 0.4,
      },
    },
    tooltip: {
      enabled: true,
      offsetY: 0,
      style: {
        fontSize: "12px",
      },
    },
  },
  yaxis: {
    show: true,
    showAlways: false,
    showForNullSeries: true,
    opposite: false,
    reversed: false,
    logarithmic: false,
    logBase: 10,
    forceNiceScale: false,
    floating: false,
    labels: {
      show: true,
      minWidth: 0,
      maxWidth: 160,
      offsetX: 0,
      offsetY: 0,
      rotate: 0,
      padding: 20,
      style: {
        colors: [],
        fontSize: "11px",
        fontWeight: 400,
        cssClass: "",
      },
    },
    axisBorder: {
      show: false,
      color: "#e0e0e0",
      width: 1,
      offsetX: 0,
      offsetY: 0,
    },
    axisTicks: {
      show: false,
      color: "#e0e0e0",
      width: 6,
      offsetX: 0,
      offsetY: 0,
    },
    title: {
      rotate: -90,
      offsetY: 0,
      offsetX: 0,
      style: {
        fontSize: "11px",
        fontWeight: 900,
        cssClass: "",
      },
    },
    tooltip: {
      enabled: false,
      offsetX: 0,
    },
    crosshairs: {
      show: true,
      position: "front",
      stroke: {
        color: "#b6b6b6",
        width: 1,
        dashArray: 0,
      },
    },
  },
  theme: {
    mode: "light",
    palette: "palette1",
    monochrome: {
      enabled: false,
      color: "#008FFB",
      shadeTo: "light",
      shadeIntensity: 0.65,
    },
  },
};
