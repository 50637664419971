<template>
  <div id="chart-code">
    <el-form @submit.native.prevent ref="form">
      <el-input
        type="textarea"
        v-model="codeText"
        @focus="$event.target.select()"
        class="textarea-code"
      ></el-input>
    </el-form>
  </div>
</template>

<script>
import { defaultConfig } from "./utils/apexcharts-config";
import { parse, stringify } from "flatted";

import { removeDifferences } from "@/utils";

export default {
  data() {
    return {
      codeText: "",
    };
  },
  mounted: function() {
    const opts = parse(stringify(this.getChartConfig().w.config));

    delete opts.chart.locales;
    if (
      opts.xaxis &&
      opts.xaxis.type !== "datetime" &&
      opts.xaxis.convertedCatToNumeric
    ) {
      delete opts.xaxis.convertedCatToNumeric;
      delete opts.xaxis.type;
    }

    if (opts.yaxis && Array.isArray(opts.yaxis) && opts.yaxis.length === 1) {
      opts.yaxis = removeDifferences(defaultConfig.yaxis, opts.yaxis[0]);
    }

    // to clean the options manually
    // - compare with the default config and
    // 1. if it's not present - delete
    // 2. if it's same - delete
    // 3. if it's an array with len>0, ignore it, else delete it
    const getCircularReplacer = () => {
      const seen = new WeakSet();
      return (key, value) => {
        if (typeof value === "object" && value !== null) {
          if (seen.has(value)) {
            return;
          }
          seen.add(value);
        }
        return value;
      };
    };

    const cleaned = removeDifferences(defaultConfig, opts);

    this.codeText = JSON.stringify(cleaned, getCircularReplacer(), 4);
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
